define("ember-file-upload/components/file-upload/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Duu76aTj",
    "block": "[[[10,\"input\"],[15,1,[30,0,[\"for\"]]],[15,\"accept\",[30,1]],[15,\"capture\",[30,2]],[15,\"multiple\",[30,3]],[15,\"disabled\",[30,4]],[15,\"onchange\",[28,[37,0],[[30,0],\"change\"],[[\"value\"],[\"target.files\"]]]],[14,\"hidden\",\"\"],[14,4,\"file\"],[12],[13],[18,5,[[30,0,[\"queue\"]]]]],[\"@accept\",\"@capture\",\"@multiple\",\"@disabled\",\"&default\"],false,[\"action\",\"yield\"]]",
    "moduleName": "ember-file-upload/components/file-upload/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});