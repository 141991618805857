define("ember-validated-form/components/validated-input/error", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isUikit"))}}
    <small id={{@id}} class="uk-text-danger" ...attributes>
      {{yield}}{{this.errorString}}
    </small>
  {{else}}
    <span
      id={{@id}}
      class={{if
        (macroCondition (macroGetOwnConfig "isBootstrap"))
        "d-block invalid-feedback"
      }}
      ...attributes
    >
      {{yield}}{{this.errorString}}
    </span>
  {{/if}}
  */
  {
    "id": "JHXj8ini",
    "block": "[[[1,\"  \"],[11,\"small\"],[16,1,[30,1]],[24,0,\"uk-text-danger\"],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,[30,0,[\"errorString\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@id\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-validated-form/components/validated-input/error.hbs",
    "isStrictMode": false
  });
  class ErrorComponent extends _component2.default {
    get errorString() {
      return this.args.errors?.join(", ");
    }
  }
  _exports.default = ErrorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ErrorComponent);
});