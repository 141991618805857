define("ember-intl-changeset-validations/instance-initializers/application", ["exports", "ember-intl-changeset-validations/utils/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /**
   * initialize runs upon app instance initialization. It simply set the instance.
   * `application.instance` is used by `app/validations/messages` to lookup the
   * i18n service so it can translate error messages.
   */
  function initialize(appInstance) {
    _application.default.instance = appInstance;
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});