define("ember-intl-changeset-validations/utils/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Export an Object with the app instance set to null. Later initilized by
   * `app/instance-initializers/application` during application boot.
   *
   * Exports {Object} containing an `instance` member (null by default).
   */
  var _default = {
    instance: null
  };
  _exports.default = _default;
});