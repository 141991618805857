define("ember-validated-form/components/validated-input/types/radio-group", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @options as |option i|}}
    {{#if (macroCondition (macroGetOwnConfig "isUikit"))}}
      {{#if (not-eq i 0)}}<br />{{/if}}
      <label
        class="uk-form-label
          {{if @isValid 'uk-text-success'}}
          {{if @isInvalid 'uk-text-danger'}}"
      >
        <input
          type="radio"
          class="uk-radio uk-margin-small-right"
          checked={{eq @value option.key}}
          value={{option.key}}
          name={{@name}}
          id="{{@inputId}}-{{i}}"
          disabled={{@disabled}}
          {{on "input" (fn this.onUpdate option.key)}}
          ...attributes
        />
        {{option.label}}
      </label>
    {{else if (macroCondition (macroGetOwnConfig "isBootstrap"))}}
      <div class="custom-control custom-radio">
        <input
          type="radio"
          class="custom-control-input
            {{if @isValid 'is-valid'}}
            {{if @isInvalid 'is-invalid'}}"
          checked={{eq @value option.key}}
          value={{option.key}}
          name={{@name}}
          id="{{@inputId}}-{{i}}"
          disabled={{@disabled}}
          {{on "input" (fn this.onUpdate option.key)}}
          ...attributes
        />
        <label
          class="custom-control-label"
          for="{{@inputId}}-{{i}}"
        >{{option.label}}</label>
      </div>
    {{else}}
      <label>
        <input
          type="radio"
          checked={{eq @value option.key}}
          value={{option.key}}
          name={{@name}}
          id="{{@inputId}}-{{i}}"
          disabled={{@disabled}}
          {{on "input" (fn this.onUpdate option.key)}}
          ...attributes
        />
        {{option.label}}
      </label>
    {{/if}}
  {{/each}}
  */
  {
    "id": "e7qk/p3U",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[41,[28,[37,3],[[30,3],0],null],[[[10,\"br\"],[12],[13]],[]],null],[1,\"\\n    \"],[10,\"label\"],[15,0,[29,[\"uk-form-label\\n        \",[52,[30,4],\"uk-text-success\"],\"\\n        \",[52,[30,5],\"uk-text-danger\"]]]],[12],[1,\"\\n      \"],[11,\"input\"],[24,4,\"radio\"],[24,0,\"uk-radio uk-margin-small-right\"],[16,\"checked\",[28,[37,4],[[30,6],[30,2,[\"key\"]]],null]],[16,2,[30,2,[\"key\"]]],[16,3,[30,7]],[16,1,[29,[[30,8],\"-\",[30,3]]]],[16,\"disabled\",[30,9]],[17,10],[4,[38,5],[\"input\",[28,[37,6],[[30,0,[\"onUpdate\"]],[30,2,[\"key\"]]],null]],null],[12],[13],[1,\"\\n      \"],[1,[30,2,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2,3]],null]],[\"@options\",\"option\",\"i\",\"@isValid\",\"@isInvalid\",\"@value\",\"@name\",\"@inputId\",\"@disabled\",\"&attrs\"],false,[\"each\",\"-track-array\",\"if\",\"not-eq\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "ember-validated-form/components/validated-input/types/radio-group.hbs",
    "isStrictMode": false
  });
  let RadioGroupComponent = (_class = class RadioGroupComponent extends _component2.default {
    onUpdate(value, event) {
      event.preventDefault();
      this.args.update(value);
      this.args.setDirty();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype)), _class);
  _exports.default = RadioGroupComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RadioGroupComponent);
});