define("ember-engines/components/link-to-external", ["exports", "@embroider/macros/es-compat", "@ember/routing", "@ember/application", "@ember/object"], function (_exports, _esCompat, _routing, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LinkToExternal;
  let LinkComponent;
  {
    let {
      LinkComponent: LegacyLinkComponent
    } = (0, _esCompat.default)(require("@ember/legacy-built-in-components"));
    LinkComponent = LegacyLinkComponent;
  }
  {
    LinkToExternal = class LinkToExternal extends LinkComponent {
      _namespaceRoute(targetRouteName) {
        const owner = (0, _application.getOwner)(this);
        if (!owner.mountPoint) {
          return super._namespaceRoute(...arguments);
        }
        const externalRoute = owner._getExternalRoute(targetRouteName);
        return externalRoute;
      }

      // override LinkTo's assertLinkToOrigin method to noop. In LinkTo, this assertion
      // checks to make sure LinkTo is not being used inside a routeless engine
      // See this PR here for more details: https://github.com/emberjs/ember.js/pull/19477
      assertLinkToOrigin() {}
    };
  }
  var _default = LinkToExternal;
  _exports.default = _default;
});