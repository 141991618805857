define("ember-engines-router-service/-private/engine-ext", ["@ember/application", "ember-engines-router-service/services/engine-router-service", "@ember/engine"], function (_application, _engineRouterService, _engine) {
  "use strict";

  _engine.default.reopen({
    buildRegistry() {
      let registry = this._super(...arguments);
      if (!(this instanceof _application.default)) {
        registry.register('service:router', _engineRouterService.default);
      }
      return registry;
    }
  });
});