define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de", {
    "caluma": {
      "caluma-query": {
        "case": {
          "status": {
            "CANCELED": "Abgebrochen",
            "COMPLETED": "Abgeschlossen",
            "RUNNING": "In Bearbeitung",
            "SUSPENDED": "Pausiert"
          }
        },
        "work-item": {
          "status": {
            "CANCELED": "Abgebrochen",
            "COMPLETED": "Erledigt",
            "READY": "Offen",
            "SKIPPED": "Übersprungen",
            "SUSPENDED": "Pausiert"
          }
        }
      },
      "form": {
        "addRow": "Zeile hinzufügen",
        "cancel": "Abbrechen",
        "close": "Schliessen",
        "delete": "Löschen",
        "deleteRow": "Möchten Sie diese Zeile wirklich löschen?",
        "edit": "Bearbeiten",
        "error": {
          "details": "Technische Details:",
          "intro": "Oh nein, auf unserer Seite ist etwas schief gelaufen. Ihre Antwort konnte nicht gespeichert werden."
        },
        "info": "Mehr Informationen",
        "navigation": {
          "next": "Weiter",
          "previous": "Zurück",
          "states": {
            "invalid": "Inkorrekt ausgefüllt",
            "unfinished": "Nicht vollständig ausgefüllt",
            "untouched": "Nicht ausgefüllt",
            "valid": "Korrekt ausgefüllt"
          }
        },
        "notification": {
          "table": {
            "add": {
              "error": "Hoppla, beim Hinzufügen des Eintrags ist etwas schief gelaufen ...",
              "success": "Eintrag wurde erfolgreich hinzugefügt."
            }
          }
        },
        "optionNotAvailable": "Diese Option ist nicht mehr verfügbar",
        "optional": "optional",
        "power-select": {
          "options-empty": "Keine Optionen vorhanden",
          "options-loading": "Lade Optionen...",
          "placeholder-multiple": "Wählen Sie eine oder mehrere Optionen aus",
          "placeholder-single": "Wählen Sie eine Option aus",
          "search-empty": "Keine Optionen gefunden",
          "search-placeholder": "Hier tippen um zu suchen"
        },
        "save": "Speichern",
        "selectFile": "Durchsuchen...",
        "validation": {
          "blank": "Bitte geben Sie einen Wert ein.",
          "deleteFailed": "Beim Löschen ist ein Fehler aufgetreten.",
          "format": "{errorMsg}",
          "greaterThanOrEqualTo": "Die Eingabe in diesem Feld darf nicht kleiner als {gte} sein",
          "inclusion": "\"{label}\" ist kein gültiger Wert für dieses Feld",
          "invalidFields": "Folgende Fragen sind noch nicht korrekt ausgefüllt:",
          "lessThanOrEqualTo": "Die Eingabe in diesem Feld darf nicht grösser als {lte} sein",
          "notAnInteger": "Bitte geben Sie eine ganze Zahl ein",
          "table": "Mindestens eine Zeile der Tabelle wurde nicht korrekt ausgefüllt",
          "tooLong": "In diesem Feld sind maximal {max} Zeichen erlaubt",
          "tooShort": "Dieses Feld muss mindestens {min} Zeichen enthalten",
          "uploadFailed": "Beim Hochladen ist ein Fehler aufgetreten."
        }
      },
      "form-builder": {
        "form": {
          "active": "Aktiv",
          "all": "Alle",
          "allForms": "Alle Formulare",
          "description": "Beschreibung",
          "draft": "Entwurf",
          "empty": "Wir haben keine Formulare gefunden. Klicken Sie den Knopf im der rechten oberen Ecke um ein Formular zu erstellen!",
          "isArchived": "Archiviert",
          "isPublished": "Publiziert",
          "loadMore": "Mehr Formulare laden",
          "name": "Name",
          "new": "Neues Formular",
          "not-found": "Kein Formular mit dem Slug '{slug}' gefunden",
          "slug": "Slug",
          "widgetOverride": "Spezialelement"
        },
        "global": {
          "cancel": "Abbrechen",
          "empty-search": "Keine Resultate gefunden. Passen Sie Ihre Suche an um bessere Resultate zu erhalten.",
          "loading": "Laden",
          "not-found": {
            "home": "Zurück zum Start",
            "subtitle": "Die angefrage Seite existiert nicht!",
            "title": "Sie sehen verloren aus"
          },
          "optional": "Optional",
          "save": "Speichern",
          "search": "Suchen"
        },
        "notification": {
          "form": {
            "add-question": {
              "error": "Hoppla, beim Hinzufügen der Frage ist etwas schief gelaufen...",
              "success": "Die Frage wurde erfolgreich zu Ihrem Formular hinzugefügt!"
            },
            "create": {
              "error": "Hoppla, beim Erstellen des Formulars ist etwas schief gelaufen...",
              "success": "Ihr Formular wurde erfolgreich erstellt!"
            },
            "remove-question": {
              "error": "Hoppla, beim Entfernen der Frage ist etwas schief gelaufen...",
              "success": "Die Frage wurde erfolgreich von Ihrem Formular entfernt!"
            },
            "reorder-options": {
              "error": "Hoppla, beim Sortieren der Optionen ist etwas schief gelaufen...",
              "success": "Ihre Optionen wurde erfolgreich sortiert!"
            },
            "reorder-questions": {
              "error": "Hoppla, beim Sortieren der Fragen ist etwas schief gelaufen...",
              "success": "Ihre Fragen wurde erfolgreich sortiert!"
            },
            "save": {
              "error": "Hoppla, beim Speichern des Formulars ist etwas schief gelaufen...",
              "success": "Ihr Formular wurde erfolgreich gespeichert!"
            }
          },
          "question": {
            "save": {
              "error": "Hoppla, beim Speichern der Frage ist etwas schief gelaufen...",
              "success": "Ihre Frage wurde erfolgreich gespeichert!"
            }
          }
        },
        "options": {
          "archive": "Option archivieren (verstecken)",
          "delete": "Option löschen",
          "restore": "Option wiederherstellen"
        },
        "question": {
          "action": "Aktion",
          "actions": {
            "COMPLETE": "Abschliessen",
            "SKIP": "Überspringen"
          },
          "add": "Neue oder existierende Frage hinzufügen",
          "advancedSettings": "Erweiterte Einstellungen",
          "calcExpression": "Berechnungsformel (JEXL)",
          "color": "Farbe",
          "colors": {
            "DEFAULT": "Standard",
            "PRIMARY": "Primär",
            "SECONDARY": "Sekundär"
          },
          "columnsToDisplay": "Spalten zur Anzeige im Formular",
          "confirmText": "Bestätigungstext",
          "confirmationText": "Bestätigungstext",
          "create": "oder eine neue Frage erstellen",
          "dataSource": "Datenquelle",
          "defaultValue": "Standardwert",
          "empty": "Sie haben noch keine Fragen hinzugefügt. Drücken Sie den obenstehenden Knopf um dies zu tun!",
          "formatValidators": "Validierung",
          "general": "Allgemein",
          "hideLabel": "Label verstecken",
          "hintText": "Hinweistext",
          "infoText": "Infotext",
          "isArchived": "Archiviert",
          "isHidden": "Versteckt (JEXL)",
          "isRequired": "Pflichtfeld",
          "label": "Label",
          "loadMore": "Mehr Fragen laden",
          "markdown": "Markdown",
          "max-length": "Maximale Länge",
          "max-value": "Maximaler Wert",
          "meta": "Metainformationen",
          "min-length": "Minimale Länge",
          "min-value": "Minimaler Wert",
          "minor-info": "Das Anpassen einer Frage darf niemals die Bedeutung der Frage verändern, um die Daten konsistent zu halten.",
          "minor-info-title": "Wichtig!",
          "new": "Neue Frage",
          "no-selection": "Keine Auswahl",
          "not-found": "Keine Frage mit dem Slug '{slug}' gefunden",
          "options": "Optionen",
          "placeholder": "Platzhalter",
          "remove": "Frage entfernen",
          "rowForm": "Formular für Tabelleneinträge",
          "search": "Nach einer Frage (Label oder Slug) suchen,",
          "search-empty": "Keine Formulare gefunden",
          "search-placeholder": "Hier tippen um zu suchen",
          "slug": "Slug",
          "staticContent": "Statischer Inhalt",
          "subForm": "Formular für Einträge",
          "supportsMarkdownPrefix": "Dieses Feld unterstützt",
          "type": "Typ",
          "type-disabled": "Der Fragetyp kann nach dem Erstellen einer Frage nicht mehr geändert werden, um korrupte Daten zu verhindern.",
          "types": {
            "ActionButtonQuestion": "Aktionsbutton",
            "CalculatedFloatQuestion": "Berechnung (Gleitkommazahl)",
            "ChoiceQuestion": "Einzelauswahl",
            "DateQuestion": "Datum",
            "DynamicChoiceQuestion": "Dynamische Einzelauswahl",
            "DynamicMultipleChoiceQuestion": "Dynamische Mehrfachauswahl",
            "FilesQuestion": "Dateien",
            "FloatQuestion": "Gleitkommazahl",
            "FormQuestion": "Formular",
            "IntegerQuestion": "Ganze Zahl",
            "MultipleChoiceQuestion": "Mehrfachauswahl",
            "StaticQuestion": "Nichtinteraktiver Inhalt",
            "TableQuestion": "Tabelle",
            "TextQuestion": "Text",
            "TextareaQuestion": "Text (mehrzeilig)"
          },
          "validateOnEnter": "Validierung beim Betreten des Fensters",
          "widgetOverride": "Anzeigetyp",
          "widgetOverrides": {
            "hidden": "Versteckt",
            "powerselect": "Power Select"
          }
        },
        "question-list": {
          "hidden": {
            "conditional": "Bedingt versteckt",
            "hidden": "Versteckt",
            "label": "Versteckt (JEXL)",
            "not-hidden": "Sichtbar"
          },
          "required": {
            "conditional": "Bedingt erforderlich",
            "label": "Erforderlich (JEXL)",
            "not-required": "Optional",
            "required": "Erforderlich"
          }
        },
        "validations": {
          "form": {
            "slug": "Ein Formular mit diesem Slug existiert bereits"
          },
          "option": {
            "slug": "Eine Option mit diesem Slug existiert bereits"
          },
          "question": {
            "slug": "Eine Frage mit diesem Slug existiert bereits"
          }
        }
      }
    },
    "dashboard": {
      "answer": "Antwort",
      "checklist": "Checkliste",
      "countAnswered": "Anzahl beantwortet",
      "countNegated": "Anzahl verneint",
      "details": {
        "close": "Schliessen",
        "heading": "Gefährdungsermittlungen in denen diese Frage vorkommt"
      },
      "from": "Von",
      "labels": {
        "completed": "Abgeschlossen",
        "overdue": "Überfällig",
        "planning": "In Planung",
        "running": "In Arbeit",
        "total": "Total"
      },
      "negatedQuestions": "Verneinte Fragen (Top 20)",
      "organisation": "Organisation",
      "percentageNegated": "Anteil verneint",
      "questionText": "Fragetext",
      "to": "Bis"
    },
    "demo": "Caluma Building Permit Demo",
    "emeis": {
      "acl-table": {
        "headings": {
          "add-entry": "Rolle und Organisationseinheit hinzufügen",
          "name": "Name",
          "role": "Rolle",
          "scope": "Organisation",
          "user": "Benutzername"
        },
        "new-user-hint": "Zugriffsrechte können erst hinzugefügt werden, sobald der neue Benutzer gespeichert wurde.",
        "title": "Zugriffsrechte"
      },
      "acl-wizzard": {
        "add-entry": "Zugriffsrechte hinzufügen",
        "duplicate": "Diese Kombination von Zugriffsrechten existiert bereits.",
        "role": {
          "select": "Klicken Sie hier um eine Rolle auszuwählen",
          "select-different": "Andere Rolle auswählen"
        },
        "scope": {
          "select": "Klicken Sie hier um einen Organisation auszuwählen",
          "select-different": "Andere Organisation auswählen"
        },
        "user": {
          "select": "Klicken Sie hier um einen Benutzer auszuwählen",
          "select-different": "Anderen Benutzer auswählen"
        }
      },
      "actions": "Aktionen",
      "empty": "Keine Einträge gefunden...",
      "export": "Export",
      "filters": {
        "active": {
          "off": "Inaktiv",
          "on": "Aktiv",
          "title": "Status"
        }
      },
      "form": {
        "activate": "Aktivieren",
        "activate-success": "Aktivierung erfolgreich.",
        "back": "Zurück",
        "cancel": "Abbrechen",
        "confirmEntryDelete": "Sind Sie sich sicher, dass Sie diesen Eintrag löschen wollen?",
        "confirmText": "Sind Sie sich sicher, dass Sie diese Aktion ausführen wollen?",
        "custom-button-action-error": "Die konfigurierte Custom Button Action ist keine valide Funktion.",
        "deactivate": "Deaktivieren",
        "deactivate-success": "Deaktivierung erfolgreich.",
        "delete": "Löschen",
        "delete-error": "Beim löschen ist ein Problem aufgetreten. Falls der Eintrag noch existiert, versuchen Sie es erneut.",
        "delete-success": "Erfolgreich gelöscht.",
        "ok": "OK",
        "phone-hint": "Buchstaben sind in Telefonnummern nicht erlaubt.",
        "save": "Speichern",
        "save-error": "Während dem Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
        "save-success": "Erfolgreich gespeichert.",
        "slug-hint": "Es sind nur Buchstaben, Nummern und Bindestriche erlaubt."
      },
      "general-error": "Ein Problem ist aufgetreten. Bitte versuchen Sie es erneut.",
      "inactive": "Inaktiv",
      "loading": "Laden...",
      "not-found": "Diese Seite konnte nicht gefunden werden.",
      "optional": "optional",
      "options": {
        "meta": {
          "scope": {
            "meta-example": "Beispiel für benutzerdefiniertes Dropdown-Feld",
            "meta-example-2": "Beispiel für benutzerdefiniertes Textfeld",
            "options": {
              "label-1": "Schinken",
              "label-2": "Käse",
              "label-3": "Zwiebeln"
            }
          },
          "user": {
            "example": "Beispiel für benutzerdefiniertes Textfeld"
          }
        }
      },
      "pagination": {
        "next": "Nächste",
        "previous": "Vorherige",
        "total": "Total"
      },
      "permissions": {
        "headings": {
          "description": "Beschreibung",
          "name": "Name",
          "roles": "Rollen",
          "slug": "Slug"
        },
        "new": "Neue Berechtigung erstellen",
        "title": "Berechtigungen"
      },
      "roles": {
        "headings": {
          "description": "Beschreibung",
          "name": "Name",
          "slug": "Slug"
        },
        "new": "Neue Rolle erstellen",
        "title": "Rollen"
      },
      "scopes": {
        "aclHint": "Zugriffsrechte können erst hinzugefügt werden, sobald die Organisation gespeichert wurde.",
        "edit": {
          "acl": {
            "title": "Berechtigung"
          },
          "edit-scope": "Organisation bearbeiten"
        },
        "emptyAclList": "Diese Organisation enthält keine Zugriffsrechte.",
        "headings": {
          "description": "Beschreibung",
          "fullName": "Name",
          "institution": "Institution",
          "parent": "Übergeordnete Organisation"
        },
        "new": "Neue Organisation erstellen",
        "title": "Organisationen"
      },
      "search": {
        "placeholder": "Suche..."
      },
      "title": "Emeis",
      "users": {
        "aclHint": "Zugriffsrechte können erst hinzugefügt werden, wenn der neue Benutzer gespeichert wurde.",
        "edit": {
          "acl": "Berechtigung",
          "edit-user": "Benutzer bearbeiten"
        },
        "emptyAclList": "Dieser Benutzer hat noch keine Zugriffsrechte.",
        "headings": {
          "address": "Adresse",
          "city": "Stadt",
          "email": "E-Mail",
          "firstName": "Vorname",
          "fullName": "Name",
          "isActive": "Aktiv",
          "language": "Sprache",
          "lastName": "Nachname",
          "phone": "Telefonnummer",
          "phone-placeholder": "+41 32 765 43 21",
          "position": "Funktion",
          "username": "Benutzername",
          "zip": "PLZ"
        },
        "invite-email": "Einladungs-Email versenden",
        "invite-email-hint": "Der Benutzer muss zuerst gespeichert werden.",
        "invite-success": "Einladung wurde versendet!",
        "new": "Benutzer hinzufügen",
        "title": "Benutzer"
      }
    },
    "global": {
      "accept": "Akzeptieren",
      "attention": "Achtung",
      "cancel": "Abbrechen",
      "continue": "Fortfahren",
      "empty": "Keine Einträge gefunden",
      "error": "Ein Fehler ist aufgetreten.",
      "gtc-body": "Bitte lesen und akzeptieren Sie vor der Verwendung dieser Software die allgemeinen Geschäftsbedingungen.",
      "gtc-title": "Allgemeine Geschäftsbedingungen",
      "loadMore": "Mehr laden",
      "noAclHint": "Ihnen wurde noch keine Berechtigung vergeben. Melden Sie sich bitte bei Ihrem SIKO.",
      "paginationInfo": "<strong>1 - {count}</strong> von {total}",
      "save": "Speichern",
      "saving": "Wird gespeichert",
      "section": "Bereich",
      "two-fa-enabled": "2FA aktiv",
      "two-fa-enabled-hint": "Zwei-Faktor-Authentifizierung aktiv",
      "unknown": "(unbekannt)",
      "workArea": "Arbeitsbereich"
    },
    "hazardIdentifications": {
      "actions": "Aktionen",
      "asc": "Aufsteigend",
      "assignee": "Beauftragte/r",
      "cancel": "Gefährdungsermittlung abbrechen",
      "cancelText": "Sie können die Gefährdungsermittlung abbrechen, wenn sie nicht mehr benötigt wird.",
      "complete": "Gefährdungsermittlung abschliessen",
      "completeHint": "Vor Abschluss müssen folgende Aufgabe(n) abgeschlossen werden.",
      "completeText": "Sie können die Gefährdungsermittlung abschliessen, wenn alle Massnahmen abgeschlossen sind.",
      "createdAt": "Erfassungsdatum",
      "data": "Stammdaten",
      "desc": "Absteigend",
      "description": "Beschreibung",
      "edit": "Gefährdungsermittlung bearbeiten",
      "editText": "Sie können die Gefährdungsermittlung bearbeiten und Ihre bisherigen Daten ändern.",
      "editToggle": "Bearbeiten",
      "empty": {
        "no-hazardIdentifications": "Es wurden noch keine Gefährdungsermittlungen erfasst."
      },
      "exportCase": "Gefährdungsermittlung exportieren",
      "exportCaseText": "Hiermit kann die Gefährdungsermittlung als Excel-Datei exportiert werden.",
      "exportMeasures": "Massnahmenplan exportieren",
      "exportMeasuresText": "Hiermit kann der Massnahmenplan als Excel-Datei exportiert werden.",
      "filters": {
        "all": "Alle",
        "status": "Status"
      },
      "forms": {
        "audit-info": "Gefährdungsermittlung"
      },
      "general": "Allgemeine Information",
      "integrity-confirmation": "Hiermit bestätige ich, dass im Rahmen dieser Gefährdungsermittlung die persönliche Integrität von Dritten sichergestellt ist.",
      "modifiedAt": "Zuletzt aktualisiert",
      "municipality": "Gemeinde",
      "name": "Bezeichnung",
      "new": {
        "title": "Gefährdungsermittlung erfassen",
        "type": "Bitte wählen Sie den Typ der Gefährdungsermittlung"
      },
      "number": "Nummer",
      "orderBy": "Sortieren nach",
      "orgUnit": "Organisationseinheit",
      "pageInfo": "Zeige {length} von {total} Gefährdungsermittlungen",
      "plan": "Fragekatalog zusammenstellen",
      "planText": "Hier können Sie den Bereich, Arbeitsbereich sowie die Checklisten der Gefährdungsermittlung auswählen.",
      "redirect": "Die Gefährdungsermittlung wurde nicht gefunden oder Sie haben keinen Zugriff darauf.",
      "states": {
        "CANCELED": "Abgebrochen",
        "COMPLETED": "Abgeschlossen",
        "RUNNING": "In Arbeit"
      },
      "status": "Status",
      "subnav": {
        "historyLogs": "Änderungsverlauf",
        "overview": "Übersicht",
        "workItems": "Aufgaben"
      },
      "title": "Gefährdungsermittlungen"
    },
    "help": {
      "download": "PDF herunterladen",
      "download_help": "Hier können Sie die alten Gefährdungsermittlungen aus der früheren Lösung herunterladen.",
      "download_link": "ZIP-Archiv herunterladen",
      "download_old": "Alte Gefährdungsermittlungen",
      "email": "Email-Adresse",
      "helpdesk": "Helpdesk H+",
      "manual": "Benutzerhandbuch",
      "manualFileName": "D-2022-09 H+ Bedienungsanleitung Benutzer Software Gefährdungsermittlung.pdf",
      "manualSiko": "Handbuch für SIKOs",
      "manualSikoFileName": "D-2023-11 HPlus Bedienungsanleitung SIKO Software Gefährdungsermittlung.pdf",
      "name": "Name",
      "phoneNumber": "Telefonnummer",
      "responsible_sikos": "Zuständige SIKOs",
      "softwareSupport": "Support für Software Gefährdungsermittlung",
      "solutions": "H+ Branchenlösung Arbeitssicherheit",
      "termsConditionsFileName": "D-2022-08 H+_AGB_Software_Checklists_BL_ASGS.pdf",
      "title": "Hilfe"
    },
    "historyLogs": {
      "actionTypes": {
        "audit_cancel": "Gefährdungsermittlung abgebrochen",
        "audit_complete": "Gefährdungsermittlung abgeschlossen",
        "audit_info": "Stammdaten",
        "measure": "Massnahmen",
        "risk_assessment": "Gefährdungsermittlung"
      },
      "changedArea": "Geänderter Bereich",
      "changedBy": "Geändert durch",
      "date": "Datum"
    },
    "master-data": {
      "addFile": "Datei hinzufügen",
      "chooseFile": "Datei auswählen",
      "cls": {
        "addChecklist": "Neue Checkliste",
        "addQuestion": "Frage hinzufügen",
        "chooseClient": "Bitte wählen Sie einen Mandanten aus, um die Checklisten zu verwalten.",
        "confirmDelete": "Wollen Sie die Checkliste wirklich löschen?",
        "deleteChecklist": "Löschen",
        "newQuestion": "Eine neue Frage hinzufügen",
        "noChecklists": "Es sind noch keine Checklisten vorhanden.",
        "noName": "< kein Titel vorhanden >",
        "release": "Änderungen freigeben",
        "release-error": "Bei der Freigabe ist ein Fehler aufgetreten",
        "release-help": "Änderungen an eigenen Checklisten werden nicht automatisch angewendet. Klicken Sie hier, um eine neue Version der Checklisten freizugeben.",
        "release-success": "Die Checklisten wurden erfolgreich freigegeben. Die neue Version wird in der nächsten Gefährdungsermittlung verwendet.",
        "saveQuestionSuccess": "Frage erfolgreich gespeichert",
        "titlePlaceholder": "Checklisten Titel"
      },
      "import": "Import",
      "importFailure": "Der Import konnte nicht durchgeführt werden.",
      "importHistory": {
        "copyToClipboard": "Detaillierte Statusmeldung in die Zwischenablage kopieren",
        "createdAt": "Erstellt am",
        "createdBy": "Erstellt von",
        "fileName": "Datei",
        "loadFailure": "Import History konnte nicht abgefragt werden.",
        "status": "Status",
        "title": "History"
      },
      "importStatus": {
        "completed": "Erfolgreich importiert",
        "error": "Fehler",
        "uploaded": "Hochgeladen, wird demnächst verarbeitet"
      },
      "importSuccess": "Der Import ist erfolgreich abgeschlossen.",
      "uploadStatusFailure": "Upload Status konnte nicht abgefragt werden."
    },
    "nav": {
      "checklists": "Checklisten",
      "dashboard": "Dashboard",
      "hazardIdentifications": "Gefährdungsermittlungen",
      "help": "Hilfe",
      "home": "Home",
      "import": "Import",
      "logout": "Abmelden",
      "masterData": "Stammdaten",
      "security": "Einstellungen",
      "support": "Support",
      "userDropdown": {
        "allClients": "Alle Mandanten"
      },
      "userManagement": "Benutzerverwaltung",
      "workItems": "Aufgaben"
    },
    "planning": {
      "all": "Alle",
      "auditDeadline": "Umsetzung bis zum",
      "auditNote": "Hinweis für Beauftragte/n",
      "complete": "Planung abschliessen",
      "completeError": "Beim Abschliessen der Planung ist ein Fehler aufgetreten",
      "completeSuccess": "Planung erfolgreich abgeschlossen",
      "default": "Vorauswahl",
      "empty": "-",
      "form": "Planungsformular",
      "noOrgUnitSelected": "Bitte wählen Sie in den Stammdaten der Gefährdungsermittlung eine Organisationseinheit aus.",
      "none": "Keine",
      "ownChecklists": "Eigene Checklisten",
      "saveError": "Beim Speichern der Planung ist ein Fehler aufgetreten",
      "saveSuccess": "Planung erfolgreich gespeichert",
      "versionDate": "Version vom"
    },
    "validations": {
      "present": "Bitte geben Sie einen Wert ein."
    },
    "workItems": {
      "actions": {
        "finish": "Massnahme abschliessen"
      },
      "assignee": "Beauftragte/r",
      "closedAt": "Erledigt am",
      "closedBy": "Erledigt von",
      "comment": "Kommentar",
      "completeError": "Vor Abschluss der Ermittlung müssen alle Massnahmen beendet werden.",
      "completedTitle": "Abgeschlossen",
      "createdAt": "Erstellt am",
      "createdBy": "Erstellt durch",
      "deadline": "Frist",
      "deficit": "Defizit / Lösungsvorschlag",
      "description": "Beschreibung",
      "documents": "Dokumente",
      "edit": "Aufgabe bearbeiten",
      "fetchError": "Fehler beim Laden der Aufgaben",
      "filters": {
        "active": "Aktiv",
        "all": "Alle",
        "closed": "Abgeschlossen",
        "control": "Controlling",
        "new": "Neu",
        "open": "Offen",
        "order": "Sortierung",
        "own": "Eigene",
        "planning": "In Planung",
        "responsible": "Verantwortlich",
        "role": "Rolle",
        "status": "Status",
        "type": "Typ",
        "unread": "Ungelesene",
        "urgent": "Dringend"
      },
      "finishSuccess": "Aufgabe erfolgreich abgeschlossen",
      "instance": "Gefährdungsermittlung",
      "measure": "Massnahme",
      "name": "Name",
      "new": "Massnahme erfassen",
      "note": "Hinweise bei Zuweisung",
      "notifications": "Benachrichtigungen",
      "notifyAssigned": "bei Zuweisung",
      "notifyCompleted": "bei Abschluss",
      "notifyDeadline": "bei Fristüberschreitung",
      "organisationUnit": "Organisationseinheit",
      "planningTitle": "Zu planen",
      "question": "Frage",
      "readyTitle": "Zu erledigen",
      "responsible": "Verantwortlich",
      "saveError": "Fehler beim Speichern der Aufgabe",
      "saveSuccess": "Aufgabe erfolgreich gespeichert",
      "task": "Aufgabe",
      "title": "Aufgaben"
    }
  }], ["fr", {
    "caluma": {
      "caluma-query": {
        "case": {
          "status": {
            "CANCELED": "Annulé",
            "COMPLETED": "Terminé",
            "RUNNING": "En cours",
            "SUSPENDED": "En pause"
          }
        },
        "work-item": {
          "status": {
            "CANCELED": "Annulé",
            "COMPLETED": "Terminé",
            "READY": "En attente",
            "SKIPPED": "Sauté",
            "SUSPENDED": "En pause"
          }
        }
      },
      "form": {
        "addRow": "Ajouter une ligne",
        "cancel": "annuler",
        "close": "fermer",
        "delete": "supprimer",
        "deleteRow": "Voulez-vous supprimer cette ligne?",
        "edit": "modifier",
        "error": {
          "details": "Détails techniques :",
          "intro": "Oh non, quelque chose a mal tourné de notre côté. Votre réponse n'a pas pu être sauvegardée."
        },
        "info": "Plus d'informations",
        "navigation": {
          "next": "Suivante",
          "previous": "Retour",
          "states": {
            "invalid": "mal rempli",
            "unfinished": "pas complètement rempli",
            "untouched": "non rempli",
            "valid": "rempli correctement"
          }
        },
        "notification": {
          "table": {
            "add": {
              "error": "Oups, quelque chose a mal tourné lors de l'ajout de l'entrée....",
              "success": "L'entrée a été ajoutée avec succès."
            }
          }
        },
        "optionNotAvailable": "Cette option n'est plus disponible",
        "optional": "optionnel",
        "power-select": {
          "options-empty": "Pas d'options disponibles",
          "options-loading": "chargez les options...",
          "placeholder-multiple": "Sélectionnez une ou plusieurs options",
          "placeholder-single": "Sélectionnez une option",
          "search-empty": "Pas d'options trouvées",
          "search-placeholder": "Tapez ici pour rechercher"
        },
        "save": "sauvegarder",
        "selectFile": "Rechercher...",
        "validation": {
          "blank": "Veuillez entrer une valeur.",
          "deleteFailed": "Une erreur est survenue lors de l'annulation.",
          "format": "{errorMsg}",
          "greaterThanOrEqualTo": "La valeur indiquée dans ce champ ne doit pas être inférieure à {gte} ",
          "inclusion": "\"{label}\" n'est pas une valeur valide pour ce champ",
          "invalidFields": "Les questions suivantes ne sont pas encore dûment remplies :",
          "lessThanOrEqualTo": "L'entrée dans ce champ ne doit pas être supérieure à {lte} ",
          "notAnInteger": "Veuillez entrer un nombre entier",
          "table": "Au moins une ligne du tableau n'a pas été remplie correctement",
          "tooLong": "Un maximum de {max} caractères est autorisé dans ce champ",
          "tooShort": "Ce champ doit contenir au moins {min} caractères",
          "uploadFailed": "Une erreur s'est produite pendant le téléchargement."
        }
      },
      "form-builder": {
        "form": {
          "active": "Actif",
          "all": "Tous",
          "allForms": "Tous les formulaires",
          "description": "Description",
          "draft": "Brouillon",
          "empty": "Nous n'avons trouvé aucun formulaire. Cliquez sur le bouton dans le coin supérieur droit pour créer un formulaire !",
          "isArchived": "Archivé",
          "isPublished": "Publié",
          "loadMore": "Télécharger plus de formulaires",
          "name": "Nom",
          "new": "Nouveau formulaire",
          "not-found": "Aucun formulaire trouvé avec le slug '{slug}'",
          "slug": "Slug",
          "widgetOverride": "Élément spécial"
        },
        "global": {
          "cancel": "Annuler",
          "empty-search": "Aucun résultat trouvé. Adaptez votre recherche pour obtenir de meilleurs résultats.",
          "loading": "Télécharger",
          "not-found": {
            "home": "Retour au départ",
            "subtitle": "La page demandée n'existe pas !",
            "title": "Vous avez l'air perdu"
          },
          "optional": "Facultatif",
          "save": "Enregistrer",
          "search": "Chercher"
        },
        "notification": {
          "form": {
            "add-question": {
              "error": "Oups, quelque chose s'est mal passé lors de l'ajout de la question...",
              "success": "La question a été ajoutée avec succès à votre formulaire !"
            },
            "create": {
              "error": "Oups, quelque chose s'est mal passé lors de la création du formulaire...",
              "success": "Votre formulaire a été créé avec succès !"
            },
            "remove-question": {
              "error": "Oups, quelque chose s'est mal passé lors de la suppression de la question...",
              "success": "La question a été supprimée avec succès de votre formulaire !"
            },
            "reorder-options": {
              "error": "Oups, quelque chose s'est mal passé lors du tri des options...",
              "success": "Vos options ont été triées avec succès !"
            },
            "reorder-questions": {
              "error": "Oups, quelque chose s'est mal passé lors du tri des questions...",
              "success": "Vos questions ont été triées avec succès !"
            },
            "save": {
              "error": "Oups, quelque chose s'est mal passé lors de l'enregistrement du formulaire...",
              "success": "Votre formulaire a été enregistré avec succès !"
            }
          },
          "question": {
            "save": {
              "error": "Oups, quelque chose s'est mal passé lors de l'enregistrement de la question...",
              "success": "Votre question a été enregistrée avec succès !"
            }
          }
        },
        "options": {
          "archive": "Archiver (masquer) l'option",
          "delete": "Supprimer l'option",
          "restore": "Restaurer l'option"
        },
        "question": {
          "action": "Action",
          "actions": {
            "COMPLETE": "Conclure",
            "SKIP": "Sauter"
          },
          "add": "Ajouter une question nouvelle ou existante",
          "advancedSettings": "Paramètres avancés",
          "calcExpression": "Formule de calcul (JEXL)",
          "color": "Couleur",
          "colors": {
            "DEFAULT": "Défaut",
            "PRIMARY": "Primaire",
            "SECONDARY": "Secondaire"
          },
          "columnsToDisplay": "Colonnes à afficher dans le formulaire",
          "confirmText": "Texte de confirmation",
          "confirmationText": "Texte de confirmation",
          "create": "ou créer une nouvelle question",
          "dataSource": "Source des données",
          "defaultValue": "Valeur par défaut",
          "empty": "Vous n'avez pas encore ajouté de questions. Appuyez sur le bouton ci-dessus pour le faire !",
          "formatValidators": "Validation",
          "general": "Général",
          "hideLabel": "Cacher l'étiquette",
          "hintText": "Texte indicatif",
          "infoText": "Texte d'information",
          "isArchived": "Archivé",
          "isHidden": "Caché (JEXL)",
          "isRequired": "Champ obligatoire",
          "label": "Étiquette",
          "loadMore": "Télécharger plus de questions",
          "markdown": "Markdown",
          "max-length": "Longueur maximale",
          "max-value": "Valeur maximale",
          "meta": "Méta-informations",
          "min-length": "Longueur minimale",
          "min-value": "Valeur minimale",
          "minor-info": "L'adaptation d'une question ne doit jamais modifier le sens de la question afin de maintenir la cohérence des données.",
          "minor-info-title": "Important !",
          "new": "Nouvelle question",
          "no-selection": "Aucune sélection",
          "not-found": "Aucune question trouvée avec le slug '{slug}'",
          "options": "Options",
          "placeholder": "Caractère générique",
          "remove": "Supprimer la question",
          "rowForm": "Formulaire pour les entrées de tableau",
          "search": "Chercher une question (étiquette ou slug)",
          "search-empty": "Pas de formulaires trouvés",
          "search-placeholder": "Tapez ici pour rechercher",
          "slug": "Slug",
          "staticContent": "Contenu statique",
          "subForm": "Formulaire pour les entrées",
          "supportsMarkdownPrefix": "Ce champ supporte",
          "type": "Type",
          "type-disabled": "Le type de question ne peut pas être modifié après la création d'une question afin d'éviter les données corrompues.",
          "types": {
            "ActionButtonQuestion": "Bouton d'action",
            "CalculatedFloatQuestion": "Calcul (nombre à virgule flottante)",
            "ChoiceQuestion": "Sélection individuel",
            "DateQuestion": "Date",
            "DynamicChoiceQuestion": "Sélection individuel dynamique",
            "DynamicMultipleChoiceQuestion": "Sélection multiple dynamique",
            "FilesQuestion": "Fichiers",
            "FloatQuestion": "Nombre à virgule flottante",
            "FormQuestion": "Formulaire",
            "IntegerQuestion": "Nombre entier",
            "MultipleChoiceQuestion": "Sélection multiple",
            "StaticQuestion": "Contenu non interactif",
            "TableQuestion": "Tableau",
            "TextQuestion": "Texte",
            "TextareaQuestion": "Texte (plusieurs lignes)"
          },
          "validateOnEnter": "Validation lors de l'entrée dans la fenêtre",
          "widgetOverride": "Type d'affichage",
          "widgetOverrides": {
            "hidden": "Caché",
            "powerselect": "Power Select"
          }
        },
        "question-list": {
          "hidden": {
            "conditional": "Conditionnellement caché",
            "hidden": "Caché",
            "label": "Caché (JEXL)",
            "not-hidden": "Visible"
          },
          "required": {
            "conditional": "Conditionnellement obligatoire",
            "label": "Obligatoire (JEXL)",
            "not-required": "Facultatif",
            "required": "Obligatoire"
          }
        },
        "validations": {
          "form": {
            "slug": "Un formulaire avec ce slug existe déjà"
          },
          "option": {
            "slug": "Une option avec ce slug existe déjà"
          },
          "question": {
            "slug": "Une question avec ce slug existe déjà"
          }
        }
      }
    },
    "dashboard": {
      "answer": "Réponse",
      "checklist": "Liste de contrôle",
      "countAnswered": "Nombre de réponses",
      "countNegated": "Nombre de réponses négatives",
      "details": {
        "close": "Terminé",
        "heading": "Enquêtes sur les Saisies des risques dans lesquelles cette question est mentionnée"
      },
      "from": "Du",
      "labels": {
        "completed": "Terminé",
        "overdue": "En retard",
        "planning": "En planification",
        "running": "En cours de travail",
        "total": "Au total"
      },
      "negatedQuestions": "Questions négatives (Top 20)",
      "organisation": "Organisation",
      "percentageNegated": "Part négatif",
      "questionText": "Texte de la question",
      "to": "Jusqu'au"
    },
    "demo": "Caluma Building Permit Demo",
    "emeis": {
      "acl-table": {
        "headings": {
          "add-entry": "Ajouter un rôle et une organisation",
          "name": "Nom",
          "role": "Rôle",
          "scope": "Organisation",
          "user": "Courriel"
        },
        "new-user-hint": "Les droits d’accès ne peuvent être ajoutés qu’une fois que le nouvel utilisateur a été enregistré.",
        "title": "Droit d'accès"
      },
      "acl-wizzard": {
        "add-entry": "Ajouter des droits d’accès",
        "duplicate": "Cette combinaison de droits d’accès existe déjà.",
        "role": {
          "select": "Cliquez ici pour sélectionner un rôle",
          "select-different": "Choisir un autre rôle"
        },
        "scope": {
          "select": "Cliquez ici pour sélectionner une organisation",
          "select-different": "Sélectionner une autre organisation"
        },
        "user": {
          "select": "Cliquez ici pour sélectionner un utilisateur",
          "select-different": "Sélectionner un autre utilisateur"
        }
      },
      "empty": "Aucune entrée trouvée ...",
      "export": "Exportation",
      "filters": {
        "active": {
          "off": "Inactif",
          "on": "Actif",
          "title": "Statut"
        }
      },
      "form": {
        "activate": "Activer",
        "activate-success": "Activation réussie.",
        "back": "Retour",
        "cancel": "Annuler",
        "confirmEntryDelete": "Voulez-vous vraiment supprimer cette saisie ?",
        "confirmText": "Êtes-vous sûr de vouloir effectuer cette action ?",
        "custom-button-action-error": "La configuration Custom Button Action n’est pas valide.",
        "deactivate": "Désactiver",
        "deactivate-success": "Désactivation réussie.",
        "delete": "Supprimer",
        "delete-error": "Un problème est apparu lors de l'effacement, si la saisie existe encore, réessayez.",
        "delete-success": "Effacé avec succès.",
        "ok": "OK",
        "phone-hint": "Les lettres ne sont pas autorisées dans les numéros de téléphone.",
        "save": "Enregistrer",
        "save-error": "Une erreur est survenue pendant l’enregistrement, veuillez réessayer.",
        "save-success": "Enregistré avec succès.",
        "slug-hint": "Seules les lettres, les numéros et les tirets sont autorisés."
      },
      "general-error": "Un problème est apparu. Veuillez réessayer, s’il vous plaît.",
      "inactive": "Inaktif",
      "loading": "Charger...",
      "not-found": "Cette page n’a pas pu être trouvée.",
      "optional": "optionnel",
      "pagination": {
        "next": "Suivante",
        "previous": "Précédente",
        "total": "Au total"
      },
      "permissions": {
        "headings": {
          "description": "Description",
          "name": "Nom",
          "roles": "Rôles",
          "slug": "Slug"
        },
        "new": "Créer une nouvelle autorisation",
        "title": "Autorisations"
      },
      "roles": {
        "headings": {
          "description": "Description",
          "name": "Nom",
          "slug": "Slug"
        },
        "new": "Créer un nouveau rôle",
        "title": "Rôles"
      },
      "scopes": {
        "aclHint": "Les droits d’autorisation ne peuvent être ajoutés qu’une fois l’organisation enregistrée.",
        "edit": {
          "acl": {
            "title": "Organisation"
          },
          "edit-scope": "Modifier l'organisation"
        },
        "emptyAclList": "Cette organisation ne contient aucun droit d’accès.",
        "headings": {
          "description": "Description",
          "fullName": "Nom",
          "institution": "Institution",
          "parent": "Organisation supérieure"
        },
        "new": "Créer une nouvelle Organisation",
        "title": "Organisations"
      },
      "search": {
        "placeholder": "Recherche..."
      },
      "title": "Emeis",
      "users": {
        "aclHint": "Les autorisations ne peuvent être ajoutées que si le nouvel utilisateur est enregistré.",
        "edit": {
          "acl": "Organisation",
          "edit-user": "Modifier l'utilisateur"
        },
        "emptyAclList": "Cet utilisateur n’a pas encore de droits d’accès.",
        "headings": {
          "address": "Adresse",
          "city": "Ville",
          "email": "Courriel",
          "firstName": "Prénom",
          "fullName": "Nom",
          "isActive": "Actif",
          "language": "Langue",
          "lastName": "Nom",
          "phone": "Numéro de téléphone",
          "phone-placeholder": "+41 32 765 43 21",
          "position": "Fonction",
          "username": "Courriel",
          "zip": "Code postal"
        },
        "invite-email": "Envoyer un courriel avec une invitation",
        "invite-email-hint": "L'utilisateur doit d'abord être enregistré.",
        "invite-success": "L’invitation a été envoyée!",
        "new": "Ajouter un utilisateur",
        "title": "Utilisateur"
      }
    },
    "global": {
      "accept": "Accepter",
      "attention": "Attention",
      "cancel": "Annuler",
      "continue": "Poursuivre",
      "empty": "Aucun élément trouvé",
      "error": "Une faute s'est produite.",
      "gtc-body": "Veuillez lire et accepter les conditions générales avant d'utiliser ce logiciel.",
      "gtc-title": "Conditions générales",
      "loadMore": "Charger d'avantage",
      "noAclHint": "Vous n'avez pas encore reçu d'autorisation. Veuillez vous adresser à votre COSEC.",
      "paginationInfo": "<strong>1 - {count}</strong> du {total}",
      "save": "Enregistrer",
      "saving": "Sauvgarder",
      "section": "Zone",
      "two-fa-enabled": "2FA actif",
      "two-fa-enabled-hint": "Authentification à deux-facteurs active",
      "unknown": "(inconnu)",
      "workArea": "Zone de travail"
    },
    "hazardIdentifications": {
      "actions": "Actions",
      "asc": "Croissant",
      "assignee": "Responsable",
      "cancel": "Annuler la Saisie des risques",
      "cancelText": "Vous pouvez annuler la Saisie des risques, si vous n'en avez plus besoin.",
      "complete": "Achever la Saisie des risques",
      "completeHint": "La tâche suivante doit être achevée avant de pouvoir clôturer la Saisie des risques.",
      "completeText": "Vous pouvez achever la Saisie des risques lorsque toutes les mesures sont terminées.",
      "createdAt": "Date de saisie",
      "data": "Données de base",
      "desc": "Décroissant",
      "description": "Description",
      "edit": "Traitement de la Saisie des risques",
      "editText": "Vous pouvez gérer la Saisie des risques et modifier les données.",
      "editToggle": "Modifier",
      "empty": {
        "no-hazardIdentifications": "Aucune saisie des risques enregister."
      },
      "exportCase": "Exporter la Saisie des risques",
      "exportCaseText": "Exporter la Saisie des risques en forme de fichier Excel.",
      "exportMeasures": "Exporter la mesure",
      "exportMeasuresText": "La planification des mesures peut ainsi être exporté sous forme de fichier Excel.",
      "filters": {
        "all": "Tout",
        "status": "Statut"
      },
      "forms": {
        "audit-info": "Saisie des risques"
      },
      "general": "Information générale",
      "integrity-confirmation": "Je confirme par la présente que l'intégrité personnelle des tiers est assurée dans le cadre de cette Saisie des risques.",
      "modifiedAt": "Dernièrement actualisé",
      "municipality": "Commune",
      "name": "Indication",
      "new": {
        "title": "Démarrer la Saisie des risques",
        "type": "S.v.p. choisisez un modèle de saisie des risques"
      },
      "number": "Numéro",
      "orderBy": "Sélectionner selon",
      "orgUnit": "Unité d'organisation",
      "pageInfo": "Montre {length} du {total} Saisie des risques",
      "plan": "Gérer un questionnaire",
      "planText": "Ici vous pouvez sélectionner la zone, la zone de travail ainsi que les listes de contrôle de la Saisie des risques.",
      "redirect": "L'identification de la Saisies des risques n'a pas été trouvée ou vous n'y avez pas accès.",
      "states": {
        "CANCELED": "Interrompu",
        "COMPLETED": "Terminé",
        "RUNNING": "En cours de travail"
      },
      "status": "Statut",
      "subnav": {
        "historyLogs": "Historique des modifications",
        "overview": "Vue d'ensemble",
        "workItems": "Tâches"
      },
      "title": "Saisie des risques"
    },
    "help": {
      "download": "Télécharger le PDF",
      "download_help": "Vous pouvez télécharger ici les saisies des risques de l'ancienne solution.",
      "download_link": "Télécharger l'archive ZIP",
      "download_old": "Ancienne solution de la saisies des risques",
      "email": "Courriel",
      "helpdesk": "Service d'assistance H+",
      "manual": "Manuel d'utilisation pour utilisateurs",
      "manualFileName": "F-2022-09 H+ Notices d'instructions utiilsateur Logiciel.pdf",
      "manualSiko": "Manuel d'utilisation pour COSEC",
      "manualSikoFileName": "F-2023-11 HPlus Notices d'instructions coordinateur Logiciel.pdf",
      "name": "Nom",
      "phoneNumber": "Numéro de téléphone",
      "responsible_sikos": "COSEC responsables",
      "softwareSupport": "Assistance pour le logiciel Saisie des risques",
      "solutions": "Solution de branche H+ Sécurité au travail",
      "termsConditionsFileName": "F-2022-08 H+_CG_Logiciel_Checklists_SB_STPS.fr.pdf",
      "title": "Aide"
    },
    "historyLogs": {
      "actionTypes": {
        "audit_cancel": "saisie des risques annulé",
        "audit_complete": "Saisie des risques terminée",
        "audit_info": "Données de base",
        "measure": "Mesures",
        "risk_assessment": "Saisie des risques"
      },
      "changedArea": "Zone modifiée",
      "changedBy": "Modifié par",
      "date": "Date"
    },
    "master-data": {
      "addFile": "Ajouter un rôle et une organisation",
      "chooseFile": "Choisir un fichier",
      "cls": {
        "addChecklist": "Listes de contrôle",
        "addQuestion": "Ajouter une question",
        "chooseClient": "Veuillez sélectionner un mandant pour gérer les listes de contrôle.",
        "confirmDelete": "Voulez-vous vraiment supprimer la liste de contrôle ?",
        "deleteChecklist": "Supprimer",
        "newQuestion": "Ajouter une nouvelle question",
        "noChecklists": "Aucune liste de contrôle n’est encore disponible.",
        "noName": "< aucun titre disponible >",
        "release": "Valider les modifications",
        "release-error": "Une erreur est survenue lors de la validation",
        "release-help": "Les modifications apportées à vos propres listes de contrôle ne sont pas automatiquement appliquées. Cliquez ici pour valider une nouvelle version des listes de contrôle.",
        "release-success": "Les listes de contrôle ont été validées avec succès. La nouvelle version sera utilisée lors de la prochaine Saisie des risques.",
        "saveQuestionSuccess": "Question enregistré avec succès",
        "titlePlaceholder": "Listes de contrôle"
      },
      "import": "Importation",
      "importFailure": "L'importation du ficher n'a pas pu être exécuter.",
      "importHistory": {
        "copyToClipboard": "Copier le message de statut détaillé dans l'archive",
        "createdAt": "Etabli le",
        "createdBy": "Etabli de",
        "fileName": "Fichier",
        "loadFailure": "L'importation de l'histoire n'a pas pu être consulter.",
        "status": "Statut",
        "title": "Histoire"
      },
      "importStatus": {
        "completed": "Importé avec succès",
        "error": "Faute",
        "uploaded": "Fichier charger, sera bientôt traiter"
      },
      "importSuccess": "L’importation est terminée avec succès.",
      "uploadStatusFailure": "Le statut de téléchargement n’a pas pu être consulté."
    },
    "nav": {
      "checklists": "Listes de contrôle",
      "dashboard": "Dashboard",
      "hazardIdentifications": "Saisie des risques",
      "help": "Aide",
      "home": "Home",
      "import": "Importation",
      "logout": "Déconnexion",
      "masterData": "Données de base",
      "security": "Paramètres",
      "support": "Soutien",
      "userDropdown": {
        "allClients": "Tous les mandataires"
      },
      "userManagement": "Utilisateurs",
      "workItems": "Tâches"
    },
    "planning": {
      "all": "Tout",
      "auditDeadline": "Réalisation jusqu'au",
      "auditNote": "Remarque pour responsable",
      "complete": "Achever la planification",
      "completeError": "Une erreur est survenue lors de l'achèvement de la planification",
      "completeSuccess": "Planification réussie",
      "default": "Présélection",
      "empty": "-",
      "form": "Formulaire de planification",
      "noOrgUnitSelected": "S.v.p. choisisez dans les données de base de la Saisie des risques une unité d'organisation.",
      "none": "Aucune",
      "ownChecklists": "Listes de contrôle individuelles",
      "saveError": "Une erreur est survenue lors de l'enregistrement de la planification",
      "saveSuccess": "Enregistrement réussi de la planification",
      "versionDate": "Version du"
    },
    "validations": {
      "present": "Veuillez entrer une valeur."
    },
    "workItems": {
      "actions": {
        "finish": "Achever la mesure"
      },
      "assignee": "Responsable",
      "closedAt": "Etabli le",
      "closedBy": "Etabli de",
      "comment": "Commentaire",
      "completeError": "Avant d'achever la Saisie des risques, toutes les mesures devront être terminées.",
      "completedTitle": "Terminé",
      "createdAt": "Etabli le",
      "createdBy": "Etabli de",
      "deadline": "Délai",
      "deficit": "Déficit / Proposition de solution",
      "description": "Description",
      "documents": "Documents",
      "edit": "Traitement de la tâche",
      "fetchError": "Faute pendant le chargement de la tâche",
      "filters": {
        "active": "Actif",
        "all": "Tout",
        "closed": "Terminé",
        "control": "Contrôle",
        "new": "Nouveau",
        "open": "Ouvert",
        "order": "Triage",
        "own": "Individuelles",
        "planning": "En planification",
        "responsible": "Responsable",
        "role": "Rôle",
        "status": "Statut",
        "type": "Modèle",
        "unread": "Non lu",
        "urgent": "Urgent"
      },
      "finishSuccess": "Tâche terminé avec succès",
      "instance": "Saisie des risques",
      "measure": "Mesures",
      "name": "Nom",
      "new": "Saisir la mesure",
      "note": "Indications en cas d'attribution",
      "notifications": "Notifications",
      "notifyAssigned": "en cas d'attribution",
      "notifyCompleted": "lors de l'achèvement",
      "notifyDeadline": "en dépassement du délai",
      "organisationUnit": "Unité d'organisation",
      "planningTitle": "Planification",
      "question": "Question",
      "readyTitle": "A régler",
      "responsible": "Responsable",
      "saveError": "Faute pendant l'enregistrement de la tâche",
      "saveSuccess": "Tâche enregistré avec succès",
      "task": "Tâche",
      "title": "Tâches"
    }
  }], ["it", {
    "caluma": {
      "form": {
        "navigation": {
          "next": "Continuato",
          "previous": "Ritorno"
        },
        "optional": "opzionale",
        "power-select": {
          "placeholder-single": "Seleziona una delle opzioni"
        },
        "selectFile": "Ricerca...",
        "validation": {
          "blank": "Inserire per favore un valore.",
          "deleteFailed": "Un errore è capitato durante l'annulazione.",
          "invalidFields": "Le domande in seguito non sono ancora regristrato come si deve:"
        }
      }
    },
    "dashboard": {
      "answer": "Risposta",
      "checklist": "Check list",
      "countAnswered": "Numero di risposte",
      "countNegated": "Numero negato",
      "details": {
        "close": "Chiudere",
        "heading": "Determinazione dei pericoli in cui si presenta questa domanda"
      },
      "from": "Dal",
      "labels": {
        "completed": "Terminato",
        "overdue": "Scaduto",
        "planning": "In pianificazione",
        "running": "In corso di trattamento",
        "total": "Totale"
      },
      "negatedQuestions": "Domande negative (Top 20)",
      "organisation": "Organizzazione",
      "percentageNegated": "Numero negato",
      "questionText": "Testo della domanda",
      "to": "Fino al"
    },
    "demo": "Caluma Building Permit Demo",
    "emeis": {
      "acl-table": {
        "headings": {
          "add-entry": "Aggiungi il ruolo e l'organizzazione",
          "name": "Nome",
          "role": "Ruolo",
          "scope": "Ambito di autorizzazione",
          "user": "Email"
        },
        "new-user-hint": "Le autorizzazioni possono essere aggiunti solo dopo che il nuovo utente è stato salvato.",
        "title": "Diritti di accesso"
      },
      "acl-wizzard": {
        "add-entry": "Aggiungi autorizzazione",
        "duplicate": "Questa combinazione di autorizzazione esiste già.",
        "role": {
          "select": "Clic qui per selezionare un ruolo",
          "select-different": "Seleziona un altro ruolo"
        },
        "scope": {
          "select": "Clic qui per selezionare un'organizzazione",
          "select-different": "Seleziona altra organizzazione"
        },
        "user": {
          "select": "Clic qui per selezionare un utente",
          "select-different": "Seleziona un altro utente"
        }
      },
      "empty": "Nessuna elemento trovato...",
      "export": "Esportazione",
      "filters": {
        "active": {
          "off": "Inattivo",
          "on": "Attivo",
          "title": "Stato"
        }
      },
      "form": {
        "activate": "Attivare",
        "activate-success": "Attivazione riuscita.",
        "back": "Ritorno",
        "cancel": "Annullato",
        "confirmEntryDelete": "Vuole sicuramente eliminare questa registrazione?",
        "confirmText": "Vuole veramente eseguire questa azione?",
        "custom-button-action-error": "La configurazione Custom Button Action non è una funzione valida.",
        "deactivate": "Disattivare",
        "deactivate-success": "Disattivazione riuscita.",
        "delete": "Cancellare",
        "delete-error": "Durante il cancellare è capitato uno sbaglio. In caso che i dati esistentano ancore, riprova per favore.",
        "delete-success": "Cancellato con successo.",
        "ok": "OK",
        "phone-hint": "Non sono consentite lettere nei numeri di telefono.",
        "save": "Salvare",
        "save-error": "Durante il salvataggio si è capitato un errore; riprova per favore.",
        "save-success": "Salvato correttamente.",
        "slug-hint": "Sono ammesse solo lettere, numeri e trattini."
      },
      "general-error": "C'è un problema, riprova, per favore.",
      "inactive": "Inattivo",
      "loading": "Caricare...",
      "not-found": "Impossibile trovare questa pagina.",
      "optional": "opzionale",
      "pagination": {
        "next": "Prossimo",
        "previous": "Precedente",
        "total": "Totale"
      },
      "permissions": {
        "headings": {
          "description": "Descrizione",
          "name": "Nome",
          "roles": "Ruoli",
          "slug": "Slug"
        },
        "new": "Crea una nuova autorizzazione",
        "title": "Autorizzazioni"
      },
      "roles": {
        "headings": {
          "description": "Descrizione",
          "name": "Nome",
          "slug": "Slug"
        },
        "new": "Crea un nuovo ruolo",
        "title": "Ruoli"
      },
      "scopes": {
        "aclHint": "Le autorizzazioni possono essere aggiunti solo dopo che l'organizzazione è stata salvata.",
        "edit": {
          "acl": {
            "title": "Autorizzazione"
          },
          "edit-scope": "Modificare l'organizzazione"
        },
        "emptyAclList": "Questa organizzazione non prevede permessi di accesso.",
        "headings": {
          "description": "Descrizione",
          "fullName": "Nome",
          "institution": "Istituzione",
          "parent": "Organizzazione superiore"
        },
        "new": "Crea una nuova organizzazione",
        "title": "Organizzazione"
      },
      "search": {
        "placeholder": "Ricerca..."
      },
      "title": "Emeis",
      "users": {
        "aclHint": "Le autorizzazioni possono essere aggiunti solo dopo che il nuovo utente è stato salvato.",
        "edit": {
          "acl": "Autorizzazione",
          "edit-user": "Modifica utente"
        },
        "emptyAclList": "Questo utente non ha ancora alcun diritto di accesso.",
        "headings": {
          "address": "Indirizzo",
          "city": "Città",
          "email": "Email",
          "firstName": "Nome",
          "fullName": "Nome",
          "isActive": "Attivo",
          "language": "Lingua",
          "lastName": "Cognome",
          "phone": "Telefono",
          "phone-placeholder": "+41 32 765 43 21",
          "position": "Funzione",
          "username": "Email",
          "zip": "CAP"
        },
        "invite-email": "Invia l'e-mail di invito",
        "invite-email-hint": "L'utente deve essere prima registrato.",
        "invite-success": "L’invito e-Mail è stato inviato!",
        "new": "Aggiungere utente",
        "title": "Utente"
      }
    },
    "global": {
      "accept": "Accettare",
      "attention": "Attenzione",
      "cancel": "Annullato",
      "continue": "Perseguire",
      "empty": "Nessuna iscrizioni trovati",
      "error": "Un errore si è prodotto.",
      "gtc-body": "Si prega di leggere e accettare i termini e le condizioni prima di utilizzare questo software.",
      "gtc-title": "Termini e condizioni generali",
      "loadMore": "Caricare di più",
      "noAclHint": "Non vi è ancora stata assegnata un'autorizzazione. Si prega di contattare il COSI.",
      "paginationInfo": "<strong>1 - {count}</strong> del {total}",
      "save": "Salvare",
      "saving": "Registrato",
      "section": "Ambito",
      "two-fa-enabled": "2 FA Attivo",
      "two-fa-enabled-hint": "Autenticazione a due-fattori attiva",
      "unknown": "(sconosciuto)",
      "workArea": "Ambito di lavoro"
    },
    "hazardIdentifications": {
      "actions": "Azione",
      "asc": "Crecsente",
      "assignee": "Incaricato per la determinazione dei pericoli",
      "cancel": "Cancellare la Determinazione dei pericoli",
      "cancelText": "Si può cancellare la Determinazione dei pericoli, se non serve più.",
      "complete": "Concludere la Determinazione dei pericoli",
      "completeHint": "Deve prima completare le seguenti compiti.",
      "completeText": "Potete concludere la Determinazione dei pericoli quando tutte le misure sono state completate.",
      "createdAt": "Data della registrazione",
      "data": "Dati di base",
      "desc": "Decrecsente",
      "description": "Descrizione",
      "edit": "Elaborazione della Determinazione dei pericoli",
      "editText": "Si può elaborare la Determinazione dei pericoli e modificare i dati precedenti.",
      "editToggle": "Modificare",
      "empty": {
        "no-hazardIdentifications": "Non c'è ancora una Determinazione dei pericoli."
      },
      "exportCase": "Esportazione della Determinazione dei pericoli",
      "exportCaseText": "In questo modo è possibile esportare la Determinazione dei pericoli come file Excel.",
      "exportMeasures": "Esportare la pianficazione della misura",
      "exportMeasuresText": "In questo modo la pianificazione delle misure può essere esportato come file Excel.",
      "filters": {
        "all": "Tutto",
        "status": "Stato"
      },
      "forms": {
        "audit-info": "Determinazione dei pericoli"
      },
      "general": "Informazione generale",
      "integrity-confirmation": "Confermo che l'integrità personale di terzi è garantita nel contesto di questa determinazione dei pericoli.",
      "modifiedAt": "Attualizzazione ulteriore",
      "municipality": "Comune",
      "name": "Indicazione",
      "new": {
        "title": "Iniziare una Determinazione dei pericoli",
        "type": "Per favore, scelga un modello della Determinazione dei pericoli"
      },
      "number": "Numero",
      "orderBy": "Selezionare secondo",
      "orgUnit": "Unità d'organizzazione",
      "pageInfo": "Mostra {length} del {total} Determinazione dei pericoli",
      "plan": "Compilare un questionario",
      "planText": "Qui si può selezionare i settori, i campi del lavoro come le check list della Determinazione dei pericoli.",
      "redirect": "L'identificazione della Determinazione dei pericoli non è stata trovata o non si ha accesso ad essa.",
      "states": {
        "CANCELED": "Interrotto",
        "COMPLETED": "Terminato",
        "RUNNING": "In corso di trattamento"
      },
      "status": "Stato",
      "subnav": {
        "historyLogs": "Percorso di cambiamento",
        "overview": "Riepilogo",
        "workItems": "Compiti"
      },
      "title": "Determinazione dei pericoli"
    },
    "help": {
      "download": "Scaricare PDF",
      "download_help": "Qui è possibile scaricare le vecchie determinazione dei pericoli della soluzione precedente.",
      "download_link": "Scaricare l' archivo ZIP",
      "download_old": "Vecchie soluzione dalla Determinazione dei pericoli",
      "email": "Email",
      "helpdesk": "H+ Assistenza",
      "manual": "Manuale d'uso per utenti",
      "manualFileName": "I-2022-09 H+ Manuale d'uso Utente Software determinazione pericoli.pdf",
      "manualSiko": "Manuale d'uso per COSI",
      "manualSikoFileName": "I-2023-11 HPlus Manuale d'uso COSI Software determinazione pericoli.pdf",
      "name": "Nome",
      "phoneNumber": "Telefono",
      "responsible_sikos": "COSI responsabili",
      "softwareSupport": "Assistenza del Software per la Determinazione dei pericoli",
      "solutions": "H+ Soluzione settoriale sicurezza sul lavoro",
      "termsConditionsFileName": "I-2022-08 H+_CG_Software_Checklists_BL_SLTS.it.pdf",
      "title": "Assistenza"
    },
    "historyLogs": {
      "actionTypes": {
        "audit_cancel": "determinazione dei pericoli cancellato",
        "audit_complete": "Determinazione dei pericoli concluso",
        "audit_info": "Dati di base",
        "measure": "Misure",
        "risk_assessment": "Determinazione dei pericoli"
      },
      "changedArea": "Ambito modificato",
      "changedBy": "Modificato da",
      "date": "Data"
    },
    "master-data": {
      "addFile": "Aggiungi file",
      "chooseFile": "Seleziona il file",
      "cls": {
        "addChecklist": "Nuova check list",
        "addQuestion": "Aggiungi domanda",
        "chooseClient": "Seleziona un mandante per gestire le check list.",
        "confirmDelete": "Vuole davvero cancellare la check list?",
        "deleteChecklist": "Cancellare",
        "newQuestion": "Aggiungere una nuova domanda",
        "noChecklists": "Non ci sono ancora delle check list.",
        "noName": "< Nessun titolo disponibile >",
        "release": "Modifiche al rilascio",
        "release-error": "Si è verificato un errore durante il rilascio",
        "release-help": "Le modifiche alle proprie Check list non vengono applicate automaticamente. Fare clic qui per rilasciare una nuova versione delle Check list.",
        "release-success": "Le Check list sono state rilasciate con successo. La nuova versione sarà utilizzata nella prossima valutazione della Determinazione dei pericoli.",
        "saveQuestionSuccess": "Frage erfolgreich gespeichert",
        "titlePlaceholder": "Titolo della check list"
      },
      "import": "Importazione",
      "importFailure": "Non è stato possibile effettuare l'importazione.",
      "importHistory": {
        "copyToClipboard": "Copiare il messaggio dello stato dettagliato nel archivio",
        "createdAt": "Emesso il",
        "createdBy": "Emesso da",
        "fileName": "File",
        "loadFailure": "L'importazione della storia non poteva essere consultato.",
        "status": "Stato",
        "title": "Storia"
      },
      "importStatus": {
        "completed": "Importato con successo",
        "error": "Sbaglio",
        "uploaded": "File caricato, sarà presto elaborato"
      },
      "importSuccess": "L'importazione del file è stato eseguito con successo.",
      "uploadStatusFailure": "Lo stato del scarico non poteva essere interrogato."
    },
    "nav": {
      "checklists": "Check list",
      "dashboard": "Dashboard",
      "hazardIdentifications": "Determinazione dei pericoli",
      "help": "Assistenza",
      "home": "Home",
      "import": "Importazione",
      "logout": "Scollegarsi",
      "masterData": "Dati di base",
      "security": "Impostazioni",
      "support": "Supporto",
      "userDropdown": {
        "allClients": "Tutti mandanti"
      },
      "userManagement": "Utente",
      "workItems": "Compiti"
    },
    "planning": {
      "all": "Tutto",
      "auditDeadline": "Realisazione fino il",
      "auditNote": "Nota per il Incaricato per la determinazione dei pericoli",
      "complete": "Terminare la pianificazione",
      "completeError": "Si è capitato uno sbaglio alla chiusura della pianificazione",
      "completeSuccess": "Pianificazione conclusa con successo",
      "default": "Preselezione",
      "empty": "-",
      "form": "Formulario di pianificazione",
      "noOrgUnitSelected": "Per favore scelga nei dati di base della Determinazione dei pericoli una unità d'organizzazione.",
      "none": "Nessune",
      "ownChecklists": "Check list individuale",
      "saveError": "Si è capitato uno sbaglio durante il salvataggio della pianificazione",
      "saveSuccess": "Pianificazione salvata con successo",
      "versionDate": "Versione da"
    },
    "validations": {
      "present": "Inserire per favore un valore."
    },
    "workItems": {
      "actions": {
        "finish": "Terminare la misura"
      },
      "assignee": "Incaricato per la determinazione dei pericoli",
      "closedAt": "Emesso il",
      "closedBy": "Emesso dal",
      "comment": "Commento",
      "completeError": "Tutte le misure devono essere terminate prima che la Determinazione dei pericoli sia completata.",
      "completedTitle": "Terminato",
      "createdAt": "Emesso il",
      "createdBy": "Emesso dal",
      "deadline": "Scadenza",
      "deficit": "Deficit / Proposta di soluzione",
      "description": "Descrizione",
      "documents": "Documenti",
      "edit": "Modificare compito",
      "fetchError": "Errore durante il carico del compito",
      "filters": {
        "active": "Attivo",
        "all": "Tutto",
        "closed": "Terminato",
        "control": "Controllo",
        "new": "Nuovo",
        "open": "Aperto",
        "order": "Classificazione",
        "own": "Individuale",
        "planning": "In pianificazione",
        "responsible": "Incaricato",
        "role": "Ruolo",
        "status": "Stato",
        "type": "Modello",
        "unread": "Non letto",
        "urgent": "Urgente"
      },
      "finishSuccess": "Compito terminato con successo",
      "instance": "Determinazione dei pericoli",
      "measure": "Misure",
      "name": "Nome",
      "new": "Registrare misura",
      "note": "Nota sull'assegnazione",
      "notifications": "Notificazione",
      "notifyAssigned": "in assegnazione",
      "notifyCompleted": "in conclusione",
      "notifyDeadline": "oltre scadenza",
      "organisationUnit": "Unità d'organizzazione",
      "planningTitle": "Da pianificare",
      "question": "Domanda",
      "readyTitle": "Da regolare",
      "responsible": "Incaricato",
      "saveError": "Errore durante la registrazione del compito",
      "saveSuccess": "Compito registrato con successo",
      "task": "Compito",
      "title": "Compiti"
    }
  }]];
  _exports.default = _default;
});