define("ember-engines-router-service/utils/root-owner", ["exports", "@ember/application", "@ember/application/instance"], function (_exports, _application, _instance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRootOwner = void 0;
  /**
   * This method is responsible for return app router from root application
   *
   * @public
   * @method getRootOwner
   * @param {Object} owner
   * @return {Router}
   */
  const getRootOwner = object => {
    const owner = (0, _application.getOwner)(object);
    if (owner instanceof _instance.default) {
      return owner;
    }
    const appRouter = owner.lookup('router:main');
    return (0, _application.getOwner)(appRouter);
  };
  _exports.getRootOwner = getRootOwner;
});