define("ember-engines-router-service/services/engine-router-service", ["exports", "@ember/service", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/application", "@ember/object/evented", "ember-engines-router-service/utils/namespace-engine-route-name", "ember-engines-router-service/utils/root-owner", "ember-engines-router-service/utils/resembles-url"], function (_exports, _service, _debug, _object, _computed, _application, _evented, _namespaceEngineRouteName, _rootOwner, _resemblesUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let EngineRouterService = (_dec = (0, _computed.reads)('externalRouter.rootURL'), _dec2 = (0, _computed.reads)('externalRouter.currentURL'), _dec3 = (0, _object.computed)('externalRouter.currentRouteName'), (_class = class EngineRouterService extends _service.default.extend(_evented.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "rootURL", _descriptor, this);
      _initializerDefineProperty(this, "currentURL", _descriptor2, this);
    }
    init() {
      super.init();
      this._externalRoutes = (0, _application.getOwner)(this)._externalRoutes;
      this._mountPoint = (0, _application.getOwner)(this).mountPoint;
      this.rootApplication = (0, _rootOwner.getRootOwner)(this);
      this.externalRouter.on('routeWillChange', this.onRouteWillChange);
      this.externalRouter.on('routeDidChange', this.onRouteDidChange);
    }
    destroy() {
      this.externalRouter.off('routeWillChange', this.onRouteWillChange);
      this.externalRouter.off('routeDidChange', this.onRouteDidChange);
      super.destroy();
    }
    onRouteWillChange() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      this.trigger('routeWillChange', ...args);
    }
    onRouteDidChange() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      this.trigger('routeDidChange', ...args);
    }
    get currentRouteName() {
      if ((0, _object.get)(this, 'externalRouter').currentRouteName === this._mountPoint) {
        return 'application';
      }
      return (0, _object.get)(this, 'externalRouter').currentRouteName.slice(this._mountPoint.length + 1);
    }
    get externalRouter() {
      return this.rootApplication.lookup('service:router');
    }
    getExternalRouteName(externalRouteName) {
      (false && !(externalRouteName in this._externalRoutes) && (0, _debug.assert)(`External route '${externalRouteName}' is unknown.`, externalRouteName in this._externalRoutes));
      return this._externalRoutes[externalRouteName];
    }
    transitionTo(routeName) {
      if ((0, _resemblesUrl.resemblesURL)(routeName)) {
        return (0, _object.get)(this, 'externalRouter').transitionTo(routeName);
      }
      for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        args[_key3 - 1] = arguments[_key3];
      }
      return (0, _object.get)(this, 'externalRouter').transitionTo((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }
    transitionToExternal(routeName) {
      for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        args[_key4 - 1] = arguments[_key4];
      }
      return (0, _object.get)(this, 'externalRouter').transitionTo(this.getExternalRouteName(routeName), ...args);
    }
    replaceWith(routeName) {
      if ((0, _resemblesUrl.resemblesURL)(routeName)) {
        return (0, _object.get)(this, 'externalRouter').replaceWith(routeName);
      }
      for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
        args[_key5 - 1] = arguments[_key5];
      }
      return (0, _object.get)(this, 'externalRouter').replaceWith((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }
    replaceWithExternal(routeName) {
      for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
        args[_key6 - 1] = arguments[_key6];
      }
      return (0, _object.get)(this, 'externalRouter').replaceWith(this.getExternalRouteName(routeName), ...args);
    }
    urlFor(routeName) {
      for (var _len7 = arguments.length, args = new Array(_len7 > 1 ? _len7 - 1 : 0), _key7 = 1; _key7 < _len7; _key7++) {
        args[_key7 - 1] = arguments[_key7];
      }
      return (0, _object.get)(this, 'externalRouter').urlFor((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }
    urlForExternal(routeName) {
      for (var _len8 = arguments.length, args = new Array(_len8 > 1 ? _len8 - 1 : 0), _key8 = 1; _key8 < _len8; _key8++) {
        args[_key8 - 1] = arguments[_key8];
      }
      return (0, _object.get)(this, 'externalRouter').urlFor(this.getExternalRouteName(routeName), ...args);
    }
    isActive(routeName) {
      for (var _len9 = arguments.length, args = new Array(_len9 > 1 ? _len9 - 1 : 0), _key9 = 1; _key9 < _len9; _key9++) {
        args[_key9 - 1] = arguments[_key9];
      }
      return (0, _object.get)(this, 'externalRouter').isActive((0, _namespaceEngineRouteName.namespaceEngineRouteName)(this._mountPoint, routeName), ...args);
    }
    isActiveExternal(routeName) {
      for (var _len10 = arguments.length, args = new Array(_len10 > 1 ? _len10 - 1 : 0), _key10 = 1; _key10 < _len10; _key10++) {
        args[_key10 - 1] = arguments[_key10];
      }
      return (0, _object.get)(this, 'externalRouter').isActive(this.getExternalRouteName(routeName), ...args);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onRouteWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRouteDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteDidChange"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "rootURL", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentURL", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "currentRouteName", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "currentRouteName"), _class.prototype)), _class));
  _exports.default = EngineRouterService;
});