define("ember-in-viewport/services/in-viewport", ["exports", "@ember/service", "@ember/object", "@ember/polyfills", "@ember/application", "@ember/debug", "@ember/runloop", "ember-in-viewport/utils/is-in-viewport", "ember-in-viewport/utils/can-use-raf", "ember-in-viewport/utils/can-use-intersection-observer", "ember-in-viewport/-private/observer-admin", "ember-in-viewport/-private/raf-admin"], function (_exports, _service, _object, _polyfills, _application, _debug, _runloop, _isInViewport, _canUseRaf, _canUseIntersectionObserver, _observerAdmin, _rafAdmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const noop = () => {};

  /**
   * ensure use on requestAnimationFrame, no matter how many components
   * on the page are using this mixin
   *
   * @class InViewport
   * @module Ember.Service
   */
  class InViewport extends _service.default {
    constructor() {
      super(...arguments);
      (0, _object.set)(this, 'registry', new WeakMap());
      let options = (0, _polyfills.assign)({
        viewportUseRAF: (0, _canUseRaf.default)()
      }, this._buildOptions());

      // set viewportUseIntersectionObserver after merging users config to avoid errors in browsers that lack support (https://github.com/DockYard/ember-in-viewport/issues/146)
      options = (0, _polyfills.assign)(options, {
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)()
      });
      (0, _object.setProperties)(this, options);
    }
    startIntersectionObserver() {
      this.observerAdmin = new _observerAdmin.default();
    }
    startRAF() {
      this.rafAdmin = new _rafAdmin.default();
    }

    /** Any strategy **/

    /**
     * @method watchElement
     * @param HTMLElement element
     * @param Object configOptions
     * @param Function enterCallback - support mixin approach
     * @param Function exitCallback - support mixin approach
     * @void
     */
    watchElement(element) {
      let configOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let enterCallback = arguments.length > 2 ? arguments[2] : undefined;
      let exitCallback = arguments.length > 3 ? arguments[3] : undefined;
      if (this.viewportUseIntersectionObserver) {
        if (!this.observerAdmin) {
          this.startIntersectionObserver();
        }
        const observerOptions = this.buildObserverOptions(configOptions);
        (0, _runloop.schedule)('afterRender', this, this.setupIntersectionObserver, element, observerOptions, enterCallback, exitCallback);
      } else {
        if (!this.rafAdmin) {
          this.startRAF();
        }
        (0, _runloop.schedule)('afterRender', this, this._startRaf, element, configOptions, enterCallback, exitCallback);
      }
      return {
        onEnter: this.addEnterCallback.bind(this, element),
        onExit: this.addExitCallback.bind(this, element)
      };
    }

    /**
     * @method addEnterCallback
     * @void
     */
    addEnterCallback(element, enterCallback) {
      if (this.viewportUseIntersectionObserver) {
        this.observerAdmin.addEnterCallback(element, enterCallback);
      } else {
        this.rafAdmin.addEnterCallback(element, enterCallback);
      }
    }

    /**
     * @method addExitCallback
     * @void
     */
    addExitCallback(element, exitCallback) {
      if (this.viewportUseIntersectionObserver) {
        this.observerAdmin.addExitCallback(element, exitCallback);
      } else {
        this.rafAdmin.addExitCallback(element, exitCallback);
      }
    }

    /** IntersectionObserver **/

    /**
     * In order to track elements and the state that comes with them, we need to keep track
     * of elements in order to get at them at a later time, specifically to unobserve
     *
     * @method addToRegistry
     * @void
     */
    addToRegistry(element, observerOptions) {
      if (this.registry) {
        this.registry.set(element, {
          observerOptions
        });
      } else {
        (false && (0, _debug.warn)('in-viewport Service has already been destroyed'));
      }
    }

    /**
     * @method setupIntersectionObserver
     * @param HTMLElement element
     * @param Object observerOptions
     * @param Function enterCallback
     * @param Function exitCallback
     * @void
     */
    setupIntersectionObserver(element, observerOptions, enterCallback, exitCallback) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.addToRegistry(element, observerOptions);
      this.observerAdmin.add(element, observerOptions, enterCallback, exitCallback);
    }
    buildObserverOptions(_ref) {
      let {
        intersectionThreshold = 0,
        scrollableArea = null,
        viewportTolerance = {}
      } = _ref;
      const domScrollableArea = typeof scrollableArea === 'string' && scrollableArea ? document.querySelector(scrollableArea) : scrollableArea instanceof HTMLElement ? scrollableArea : undefined;

      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      // IntersectionObserver takes either a Document Element or null for `root`
      const {
        top = 0,
        left = 0,
        bottom = 0,
        right = 0
      } = viewportTolerance;
      return {
        root: domScrollableArea,
        rootMargin: `${top}px ${right}px ${bottom}px ${left}px`,
        threshold: intersectionThreshold
      };
    }
    unobserveIntersectionObserver(target) {
      if (!target) {
        return;
      }
      const registeredTarget = this.registry.get(target);
      if (typeof registeredTarget === 'object') {
        this.observerAdmin.unobserve(target, registeredTarget.observerOptions);
      }
    }

    /** RAF **/

    addRAF(elementId, fn) {
      this.rafAdmin.add(elementId, fn);
    }
    removeRAF(elementId) {
      if (this.rafAdmin) {
        this.rafAdmin.remove(elementId);
      }
    }
    isInViewport() {
      return (0, _isInViewport.default)(...arguments);
    }

    /** other **/
    stopWatching(target) {
      if (this.observerAdmin) {
        this.unobserveIntersectionObserver(target);
      }
      if (this.rafAdmin) {
        this.removeRAF(target);
      }
    }
    willDestroy() {
      (0, _object.set)(this, 'registry', null);
      if (this.observerAdmin) {
        this.observerAdmin.destroy();
        (0, _object.set)(this, 'observerAdmin', null);
      }
      if (this.rafAdmin) {
        this.rafAdmin.reset();
        (0, _object.set)(this, 'rafAdmin', null);
      }
    }
    _buildOptions() {
      let defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const owner = (0, _application.getOwner)(this);
      if (owner) {
        return (0, _polyfills.assign)(defaultOptions, owner.lookup('config:in-viewport'));
      }
    }
    _startRaf(element, configOptions, enterCallback, exitCallback) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      enterCallback = enterCallback || noop;
      exitCallback = exitCallback || noop;

      // this isn't using the same functions as the mixin case, but that is b/c it is a bit harder to unwind.
      // So just rewrote it with pure functions for now
      (0, _rafAdmin.startRAF)(element, configOptions, enterCallback, exitCallback, this.addRAF.bind(this, element.id), this.removeRAF.bind(this, element.id));
    }
  }
  _exports.default = InViewport;
});