define("ember-intl-changeset-validations/validations/messages", ["exports", "ember-changeset-validations/utils/messages", "ember-intl-changeset-validations/utils/application"], function (_exports, _messages, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    keys
  } = Object;
  const intl = _application.default.instance.lookup('service:intl');
  const messages = {};
  keys(_messages.default).forEach(key => {
    const lookupKey = `validations.${key}`;
    if (intl.exists(lookupKey)) {
      const lookupResult = intl.lookup(lookupKey);
      messages[key] = 'string' !== typeof lookupResult ? lookupResult.reduce((acc, curr) => {
        if (curr.type === 1) {
          return acc + `{${curr.value}}`;
        } else {
          return acc + curr.value;
        }
      }, '') : lookupResult;
    } else {
      messages[key] = _messages.default[key];
    }
  });
  var _default = messages;
  _exports.default = _default;
});