define("ember-validated-form/helpers/class-list", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _helper.helper)(function classList(classes) {
    return classes.filter(cls => !(0, _utils.isEmpty)(cls)).map(cls => cls.trim()).join(" ");
  });
  _exports.default = _default;
});